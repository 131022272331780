import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/agreement'
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('./views/pay/agreement'),
    meta: {
      title: '迪赛特病理'
    }
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('./views/pay/result'),
    meta: {
      title: '迪赛特病理'
    }
  },
  {
    path: '/agreement-advice',
    name: 'AgreementAdvice',
    component: () => import('./views/pay/agreement-advice'),
    meta: {
      title: '迪赛特病理'
    }
  },
]

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
})

const router = new Router({ routes })

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export {
  router
}